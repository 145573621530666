html {
    font-family: sans-serif;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%
}

body {
    margin: 0
}

article,
footer,
header,
main,
menu,
nav,
section {
    display: block
}

[hidden] {
    display: none
}

a {
    background-color: transparent
}

a:active,
a:hover {
    outline: 0
}

b,
strong {
    font-weight: 700
}

h1 {
    margin: .67em 0;
    font-size: 2em
}

img {
    border: 0
}

pre {
    overflow: auto
}

pre {
    font-family: monospace, monospace;
    font-size: 1em
}

button,
input,
select {
    margin: 0;
    font: inherit;
    color: inherit
}

button {
    overflow: visible
}

button,
select {
    text-transform: none
}

button,
html input[type=button],
input[type=reset],
input[type=submit] {
    -webkit-appearance: button;
    cursor: pointer
}

button[disabled],
html input[disabled] {
    cursor: default
}

button::-moz-focus-inner,
input::-moz-focus-inner {
    padding: 0;
    border: 0
}

input {
    line-height: normal
}

input[type=checkbox],
input[type=radio] {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    height: auto
}

input[type=search] {
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    -webkit-appearance: textfield
}

input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-decoration {
    -webkit-appearance: none
}

/*! Source: https://github.com/h5bp/html5-boilerplate/blob/master/src/css/main.css */

@media print {
    *,
    :after,
    :before {
        color: #000 !important;
        text-shadow: none !important;
        background: 0 0 !important;
        -webkit-box-shadow: none !important;
        box-shadow: none !important
    }
    a,
    a:visited {
        text-decoration: underline
    }
    a[href]:after {
        content: " (" attr(href) ")"
    }
    a[href^="#"]:after,
    a[href^="javascript:"]:after {
        content: ""
    }
    pre {
        border: 1px solid #999;
        page-break-inside: avoid
    }
    img {
        page-break-inside: avoid
    }
    img {
        max-width: 100% !important
    }
    h2,
    h3,
    p {
        orphans: 3;
        widows: 3
    }
    h2,
    h3 {
        page-break-after: avoid
    }
    .navbar {
        display: none
    }
    .btn>.caret {
        border-top-color: #000 !important
    }
    .label {
        border: 1px solid #000
    }
}

* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box
}

:after,
:before {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box
}

html {
    font-size: 10px;
    -webkit-tap-highlight-color: transparent
}

body {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14px;
    line-height: 1.42857143;
    color: #333;
    background-color: #fff
}

button,
input,
select {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit
}

a {
    color: #337ab7;
    text-decoration: none
}

a:focus,
a:hover {
    color: #23527c;
    text-decoration: underline
}

a:focus {
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px
}

img {
    vertical-align: middle
}

.img-responsive {
    display: block;
    max-width: 100%;
    height: auto
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0
}

[role=button] {
    cursor: pointer
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: inherit;
    font-weight: 500;
    line-height: 1.1;
    color: inherit
}

.h1,
.h2,
.h3,
h1,
h2,
h3 {
    margin-top: 20px;
    margin-bottom: 10px
}

.h4,
.h5,
.h6,
h4,
h5,
h6 {
    margin-top: 10px;
    margin-bottom: 10px
}

.h1,
h1 {
    font-size: 36px
}

.h2,
h2 {
    font-size: 30px
}

.h3,
h3 {
    font-size: 24px
}

.h4,
h4 {
    font-size: 18px
}

.h5,
h5 {
    font-size: 14px
}

.h6,
h6 {
    font-size: 12px
}

p {
    margin: 0 0 10px
}

.text-left {
    text-align: left
}

.text-right {
    text-align: right
}

.text-center {
    text-align: center
}

.text-success {
    color: #3c763d
}

a.text-success:focus,
a.text-success:hover {
    color: #2b542c
}

.text-info {
    color: #31708f
}

a.text-info:focus,
a.text-info:hover {
    color: #245269
}

.bg-success {
    background-color: #dff0d8
}

a.bg-success:focus,
a.bg-success:hover {
    background-color: #c1e2b3
}

.bg-info {
    background-color: #d9edf7
}

a.bg-info:focus,
a.bg-info:hover {
    background-color: #afd9ee
}

ul {
    margin-top: 0;
    margin-bottom: 10px
}

ul ul {
    margin-bottom: 0
}

dt {
    line-height: 1.42857143
}

dt {
    font-weight: 700
}

pre {
    font-family: Menlo, Monaco, Consolas, "Courier New", monospace
}

pre {
    display: block;
    padding: 9.5px;
    margin: 0 0 10px;
    font-size: 13px;
    line-height: 1.42857143;
    color: #333;
    word-break: break-all;
    word-wrap: break-word;
    background-color: #f5f5f5;
    border: 1px solid #ccc;
    border-radius: 4px
}

.container {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto
}

@media (min-width:768px) {
    .container {
        width: 750px
    }
}

@media (min-width:992px) {
    .container {
        width: 970px
    }
}

@media (min-width:1200px) {
    .container {
        width: 1170px
    }
}

.container-fluid {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto
}

.row {
    margin-right: -15px;
    margin-left: -15px
}

.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-xs-1,
.col-xs-10,
.col-xs-11,
.col-xs-12,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9 {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px
}

.col-xs-1,
.col-xs-10,
.col-xs-11,
.col-xs-12,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9 {
    float: left
}

.col-xs-12 {
    width: 100%
}

.col-xs-11 {
    width: 91.66666667%
}

.col-xs-10 {
    width: 83.33333333%
}

.col-xs-9 {
    width: 75%
}

.col-xs-8 {
    width: 66.66666667%
}

.col-xs-7 {
    width: 58.33333333%
}

.col-xs-6 {
    width: 50%
}

.col-xs-5 {
    width: 41.66666667%
}

.col-xs-4 {
    width: 33.33333333%
}

.col-xs-3 {
    width: 25%
}

.col-xs-2 {
    width: 16.66666667%
}

.col-xs-1 {
    width: 8.33333333%
}

.col-xs-push-12 {
    left: 100%
}

.col-xs-push-11 {
    left: 91.66666667%
}

.col-xs-push-10 {
    left: 83.33333333%
}

.col-xs-push-9 {
    left: 75%
}

.col-xs-push-8 {
    left: 66.66666667%
}

.col-xs-push-7 {
    left: 58.33333333%
}

.col-xs-push-6 {
    left: 50%
}

.col-xs-push-5 {
    left: 41.66666667%
}

.col-xs-push-4 {
    left: 33.33333333%
}

.col-xs-push-3 {
    left: 25%
}

.col-xs-push-2 {
    left: 16.66666667%
}

.col-xs-push-1 {
    left: 8.33333333%
}

.col-xs-push-0 {
    left: auto
}

.col-xs-offset-12 {
    margin-left: 100%
}

.col-xs-offset-11 {
    margin-left: 91.66666667%
}

.col-xs-offset-10 {
    margin-left: 83.33333333%
}

.col-xs-offset-9 {
    margin-left: 75%
}

.col-xs-offset-8 {
    margin-left: 66.66666667%
}

.col-xs-offset-7 {
    margin-left: 58.33333333%
}

.col-xs-offset-6 {
    margin-left: 50%
}

.col-xs-offset-5 {
    margin-left: 41.66666667%
}

.col-xs-offset-4 {
    margin-left: 33.33333333%
}

.col-xs-offset-3 {
    margin-left: 25%
}

.col-xs-offset-2 {
    margin-left: 16.66666667%
}

.col-xs-offset-1 {
    margin-left: 8.33333333%
}

.col-xs-offset-0 {
    margin-left: 0
}

@media (min-width:768px) {
    .col-sm-1,
    .col-sm-10,
    .col-sm-11,
    .col-sm-12,
    .col-sm-2,
    .col-sm-3,
    .col-sm-4,
    .col-sm-5,
    .col-sm-6,
    .col-sm-7,
    .col-sm-8,
    .col-sm-9 {
        float: left
    }
    .col-sm-12 {
        width: 100%
    }
    .col-sm-11 {
        width: 91.66666667%
    }
    .col-sm-10 {
        width: 83.33333333%
    }
    .col-sm-9 {
        width: 75%
    }
    .col-sm-8 {
        width: 66.66666667%
    }
    .col-sm-7 {
        width: 58.33333333%
    }
    .col-sm-6 {
        width: 50%
    }
    .col-sm-5 {
        width: 41.66666667%
    }
    .col-sm-4 {
        width: 33.33333333%
    }
    .col-sm-3 {
        width: 25%
    }
    .col-sm-2 {
        width: 16.66666667%
    }
    .col-sm-1 {
        width: 8.33333333%
    }
    .col-sm-push-12 {
        left: 100%
    }
    .col-sm-push-11 {
        left: 91.66666667%
    }
    .col-sm-push-10 {
        left: 83.33333333%
    }
    .col-sm-push-9 {
        left: 75%
    }
    .col-sm-push-8 {
        left: 66.66666667%
    }
    .col-sm-push-7 {
        left: 58.33333333%
    }
    .col-sm-push-6 {
        left: 50%
    }
    .col-sm-push-5 {
        left: 41.66666667%
    }
    .col-sm-push-4 {
        left: 33.33333333%
    }
    .col-sm-push-3 {
        left: 25%
    }
    .col-sm-push-2 {
        left: 16.66666667%
    }
    .col-sm-push-1 {
        left: 8.33333333%
    }
    .col-sm-push-0 {
        left: auto
    }
    .col-sm-offset-12 {
        margin-left: 100%
    }
    .col-sm-offset-11 {
        margin-left: 91.66666667%
    }
    .col-sm-offset-10 {
        margin-left: 83.33333333%
    }
    .col-sm-offset-9 {
        margin-left: 75%
    }
    .col-sm-offset-8 {
        margin-left: 66.66666667%
    }
    .col-sm-offset-7 {
        margin-left: 58.33333333%
    }
    .col-sm-offset-6 {
        margin-left: 50%
    }
    .col-sm-offset-5 {
        margin-left: 41.66666667%
    }
    .col-sm-offset-4 {
        margin-left: 33.33333333%
    }
    .col-sm-offset-3 {
        margin-left: 25%
    }
    .col-sm-offset-2 {
        margin-left: 16.66666667%
    }
    .col-sm-offset-1 {
        margin-left: 8.33333333%
    }
    .col-sm-offset-0 {
        margin-left: 0
    }
}

@media (min-width:992px) {
    .col-md-1,
    .col-md-10,
    .col-md-11,
    .col-md-12,
    .col-md-2,
    .col-md-3,
    .col-md-4,
    .col-md-5,
    .col-md-6,
    .col-md-7,
    .col-md-8,
    .col-md-9 {
        float: left
    }
    .col-md-12 {
        width: 100%
    }
    .col-md-11 {
        width: 91.66666667%
    }
    .col-md-10 {
        width: 83.33333333%
    }
    .col-md-9 {
        width: 75%
    }
    .col-md-8 {
        width: 66.66666667%
    }
    .col-md-7 {
        width: 58.33333333%
    }
    .col-md-6 {
        width: 50%
    }
    .col-md-5 {
        width: 41.66666667%
    }
    .col-md-4 {
        width: 33.33333333%
    }
    .col-md-3 {
        width: 25%
    }
    .col-md-2 {
        width: 16.66666667%
    }
    .col-md-1 {
        width: 8.33333333%
    }
    .col-md-push-12 {
        left: 100%
    }
    .col-md-push-11 {
        left: 91.66666667%
    }
    .col-md-push-10 {
        left: 83.33333333%
    }
    .col-md-push-9 {
        left: 75%
    }
    .col-md-push-8 {
        left: 66.66666667%
    }
    .col-md-push-7 {
        left: 58.33333333%
    }
    .col-md-push-6 {
        left: 50%
    }
    .col-md-push-5 {
        left: 41.66666667%
    }
    .col-md-push-4 {
        left: 33.33333333%
    }
    .col-md-push-3 {
        left: 25%
    }
    .col-md-push-2 {
        left: 16.66666667%
    }
    .col-md-push-1 {
        left: 8.33333333%
    }
    .col-md-push-0 {
        left: auto
    }
    .col-md-offset-12 {
        margin-left: 100%
    }
    .col-md-offset-11 {
        margin-left: 91.66666667%
    }
    .col-md-offset-10 {
        margin-left: 83.33333333%
    }
    .col-md-offset-9 {
        margin-left: 75%
    }
    .col-md-offset-8 {
        margin-left: 66.66666667%
    }
    .col-md-offset-7 {
        margin-left: 58.33333333%
    }
    .col-md-offset-6 {
        margin-left: 50%
    }
    .col-md-offset-5 {
        margin-left: 41.66666667%
    }
    .col-md-offset-4 {
        margin-left: 33.33333333%
    }
    .col-md-offset-3 {
        margin-left: 25%
    }
    .col-md-offset-2 {
        margin-left: 16.66666667%
    }
    .col-md-offset-1 {
        margin-left: 8.33333333%
    }
    .col-md-offset-0 {
        margin-left: 0
    }
}

@media (min-width:1200px) {
    .col-lg-1,
    .col-lg-10,
    .col-lg-11,
    .col-lg-12,
    .col-lg-2,
    .col-lg-3,
    .col-lg-4,
    .col-lg-5,
    .col-lg-6,
    .col-lg-7,
    .col-lg-8,
    .col-lg-9 {
        float: left
    }
    .col-lg-12 {
        width: 100%
    }
    .col-lg-11 {
        width: 91.66666667%
    }
    .col-lg-10 {
        width: 83.33333333%
    }
    .col-lg-9 {
        width: 75%
    }
    .col-lg-8 {
        width: 66.66666667%
    }
    .col-lg-7 {
        width: 58.33333333%
    }
    .col-lg-6 {
        width: 50%
    }
    .col-lg-5 {
        width: 41.66666667%
    }
    .col-lg-4 {
        width: 33.33333333%
    }
    .col-lg-3 {
        width: 25%
    }
    .col-lg-2 {
        width: 16.66666667%
    }
    .col-lg-1 {
        width: 8.33333333%
    }
    .col-lg-push-12 {
        left: 100%
    }
    .col-lg-push-11 {
        left: 91.66666667%
    }
    .col-lg-push-10 {
        left: 83.33333333%
    }
    .col-lg-push-9 {
        left: 75%
    }
    .col-lg-push-8 {
        left: 66.66666667%
    }
    .col-lg-push-7 {
        left: 58.33333333%
    }
    .col-lg-push-6 {
        left: 50%
    }
    .col-lg-push-5 {
        left: 41.66666667%
    }
    .col-lg-push-4 {
        left: 33.33333333%
    }
    .col-lg-push-3 {
        left: 25%
    }
    .col-lg-push-2 {
        left: 16.66666667%
    }
    .col-lg-push-1 {
        left: 8.33333333%
    }
    .col-lg-push-0 {
        left: auto
    }
    .col-lg-offset-12 {
        margin-left: 100%
    }
    .col-lg-offset-11 {
        margin-left: 91.66666667%
    }
    .col-lg-offset-10 {
        margin-left: 83.33333333%
    }
    .col-lg-offset-9 {
        margin-left: 75%
    }
    .col-lg-offset-8 {
        margin-left: 66.66666667%
    }
    .col-lg-offset-7 {
        margin-left: 58.33333333%
    }
    .col-lg-offset-6 {
        margin-left: 50%
    }
    .col-lg-offset-5 {
        margin-left: 41.66666667%
    }
    .col-lg-offset-4 {
        margin-left: 33.33333333%
    }
    .col-lg-offset-3 {
        margin-left: 25%
    }
    .col-lg-offset-2 {
        margin-left: 16.66666667%
    }
    .col-lg-offset-1 {
        margin-left: 8.33333333%
    }
    .col-lg-offset-0 {
        margin-left: 0
    }
}

label {
    display: inline-block;
    max-width: 100%;
    margin-bottom: 5px;
    font-weight: 700
}

input[type=search] {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box
}

input[type=checkbox],
input[type=radio] {
    margin: 4px 0 0;
    line-height: normal
}

input[type=file] {
    display: block
}

input[type=range] {
    display: block;
    width: 100%
}

select[multiple],
select[size] {
    height: auto
}

input[type=checkbox]:focus,
input[type=file]:focus,
input[type=radio]:focus {
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px
}

.form-control {
    display: block;
    width: 100%;
    height: 34px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
    -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
    -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s
}

.form-control:focus {
    border-color: #66afe9;
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, .6);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, .6)
}

.form-control::-moz-placeholder {
    color: #999;
    opacity: 1
}

.form-control:-ms-input-placeholder {
    color: #999
}

.form-control::-webkit-input-placeholder {
    color: #999
}

.form-control::-ms-expand {
    background-color: transparent;
    border: 0
}

.form-control[disabled],
.form-control[readonly] {
    background-color: #eee;
    opacity: 1
}

.form-control[disabled] {
    cursor: not-allowed
}

input[type=search] {
    -webkit-appearance: none
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
    input[type=date].form-control,
    input[type=datetime-local].form-control,
    input[type=month].form-control,
    input[type=time].form-control {
        line-height: 34px
    }
    .input-group-sm input[type=date],
    .input-group-sm input[type=datetime-local],
    .input-group-sm input[type=month],
    .input-group-sm input[type=time],
    input[type=date].input-sm,
    input[type=datetime-local].input-sm,
    input[type=month].input-sm,
    input[type=time].input-sm {
        line-height: 30px
    }
    .input-group-lg input[type=date],
    .input-group-lg input[type=datetime-local],
    .input-group-lg input[type=month],
    .input-group-lg input[type=time],
    input[type=date].input-lg,
    input[type=datetime-local].input-lg,
    input[type=month].input-lg,
    input[type=time].input-lg {
        line-height: 46px
    }
}

.form-group {
    margin-bottom: 15px
}

input[type=checkbox][disabled],
input[type=radio][disabled] {
    cursor: not-allowed
}

.input-sm {
    height: 30px;
    padding: 5px 10px;
    font-size: 12px;
    line-height: 1.5;
    border-radius: 3px
}

select.input-sm {
    height: 30px;
    line-height: 30px
}

select[multiple].input-sm {
    height: auto
}

.form-group-sm .form-control {
    height: 30px;
    padding: 5px 10px;
    font-size: 12px;
    line-height: 1.5;
    border-radius: 3px
}

.form-group-sm select.form-control {
    height: 30px;
    line-height: 30px
}

.form-group-sm select[multiple].form-control {
    height: auto
}

.input-lg {
    height: 46px;
    padding: 10px 16px;
    font-size: 18px;
    line-height: 1.3333333;
    border-radius: 6px
}

select.input-lg {
    height: 46px;
    line-height: 46px
}

select[multiple].input-lg {
    height: auto
}

.form-group-lg .form-control {
    height: 46px;
    padding: 10px 16px;
    font-size: 18px;
    line-height: 1.3333333;
    border-radius: 6px
}

.form-group-lg select.form-control {
    height: 46px;
    line-height: 46px
}

.form-group-lg select[multiple].form-control {
    height: auto
}

@media (min-width:768px) {
    .form-inline .form-group {
        display: inline-block;
        margin-bottom: 0;
        vertical-align: middle
    }
    .form-inline .form-control {
        display: inline-block;
        width: auto;
        vertical-align: middle
    }
    .form-inline .input-group {
        display: inline-table;
        vertical-align: middle
    }
    .form-inline .input-group .form-control,
    .form-inline .input-group .input-group-btn {
        width: auto
    }
    .form-inline .input-group>.form-control {
        width: 100%
    }
    .form-inline .control-label {
        margin-bottom: 0;
        vertical-align: middle
    }
}

.btn {
    display: inline-block;
    padding: 6px 12px;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 4px
}

.btn.focus,
.btn:active.focus,
.btn:active:focus,
.btn:focus {
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px
}

.btn.focus,
.btn:focus,
.btn:hover {
    color: #333;
    text-decoration: none
}

.btn:active {
    background-image: none;
    outline: 0;
    -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125)
}

.btn[disabled] {
    cursor: not-allowed;
    -webkit-box-shadow: none;
    box-shadow: none;
    opacity: .65
}

.btn-default {
    color: #333;
    background-color: #fff;
    border-color: #ccc
}

.btn-default.focus,
.btn-default:focus {
    color: #333;
    background-color: #e6e6e6;
    border-color: #8c8c8c
}

.btn-default:hover {
    color: #333;
    background-color: #e6e6e6;
    border-color: #adadad
}

.btn-default:active,
.open>.dropdown-toggle.btn-default {
    color: #333;
    background-color: #e6e6e6;
    border-color: #adadad
}

.btn-default:active.focus,
.btn-default:active:focus,
.btn-default:active:hover,
.open>.dropdown-toggle.btn-default.focus,
.open>.dropdown-toggle.btn-default:focus,
.open>.dropdown-toggle.btn-default:hover {
    color: #333;
    background-color: #d4d4d4;
    border-color: #8c8c8c
}

.btn-default:active,
.open>.dropdown-toggle.btn-default {
    background-image: none
}

.btn-default[disabled].focus,
.btn-default[disabled]:focus,
.btn-default[disabled]:hover {
    background-color: #fff;
    border-color: #ccc
}

.btn-success {
    color: #fff;
    background-color: #5cb85c;
    border-color: #4cae4c
}

.btn-success.focus,
.btn-success:focus {
    color: #fff;
    background-color: #449d44;
    border-color: #255625
}

.btn-success:hover {
    color: #fff;
    background-color: #449d44;
    border-color: #398439
}

.btn-success:active,
.open>.dropdown-toggle.btn-success {
    color: #fff;
    background-color: #449d44;
    border-color: #398439
}

.btn-success:active.focus,
.btn-success:active:focus,
.btn-success:active:hover,
.open>.dropdown-toggle.btn-success.focus,
.open>.dropdown-toggle.btn-success:focus,
.open>.dropdown-toggle.btn-success:hover {
    color: #fff;
    background-color: #398439;
    border-color: #255625
}

.btn-success:active,
.open>.dropdown-toggle.btn-success {
    background-image: none
}

.btn-success[disabled].focus,
.btn-success[disabled]:focus,
.btn-success[disabled]:hover {
    background-color: #5cb85c;
    border-color: #4cae4c
}

.btn-info {
    color: #fff;
    background-color: #5bc0de;
    border-color: #46b8da
}

.btn-info.focus,
.btn-info:focus {
    color: #fff;
    background-color: #31b0d5;
    border-color: #1b6d85
}

.btn-info:hover {
    color: #fff;
    background-color: #31b0d5;
    border-color: #269abc
}

.btn-info:active,
.open>.dropdown-toggle.btn-info {
    color: #fff;
    background-color: #31b0d5;
    border-color: #269abc
}

.btn-info:active.focus,
.btn-info:active:focus,
.btn-info:active:hover,
.open>.dropdown-toggle.btn-info.focus,
.open>.dropdown-toggle.btn-info:focus,
.open>.dropdown-toggle.btn-info:hover {
    color: #fff;
    background-color: #269abc;
    border-color: #1b6d85
}

.btn-info:active,
.open>.dropdown-toggle.btn-info {
    background-image: none
}

.btn-info[disabled].focus,
.btn-info[disabled]:focus,
.btn-info[disabled]:hover {
    background-color: #5bc0de;
    border-color: #46b8da
}

.btn-link {
    font-weight: 400;
    color: #337ab7;
    border-radius: 0
}

.btn-link,
.btn-link:active,
.btn-link[disabled] {
    background-color: transparent;
    -webkit-box-shadow: none;
    box-shadow: none
}

.btn-link,
.btn-link:active,
.btn-link:focus,
.btn-link:hover {
    border-color: transparent
}

.btn-link:focus,
.btn-link:hover {
    color: #23527c;
    text-decoration: underline;
    background-color: transparent
}

.btn-link[disabled]:focus,
.btn-link[disabled]:hover {
    color: #777;
    text-decoration: none
}

.btn-group-lg>.btn,
.btn-lg {
    padding: 10px 16px;
    font-size: 18px;
    line-height: 1.3333333;
    border-radius: 6px
}

.btn-group-sm>.btn,
.btn-sm {
    padding: 5px 10px;
    font-size: 12px;
    line-height: 1.5;
    border-radius: 3px
}

.btn-group-xs>.btn,
.btn-xs {
    padding: 1px 5px;
    font-size: 12px;
    line-height: 1.5;
    border-radius: 3px
}

.btn-block {
    display: block;
    width: 100%
}

.btn-block+.btn-block {
    margin-top: 5px
}

input[type=button].btn-block,
input[type=reset].btn-block,
input[type=submit].btn-block {
    width: 100%
}

.collapse {
    display: none
}

.collapse.in {
    display: block
}

.fade {
    opacity: 0;
    -webkit-transition: opacity .15s linear;
    -o-transition: opacity .15s linear;
    transition: opacity .15s linear
}

.fade.in {
    opacity: 1
}

.collapse {
    display: none
}

.collapse.in {
    display: block
}

tr.collapse.in {
    display: table-row
}

tbody.collapse.in {
    display: table-row-group
}

.collapsing {
    position: relative;
    height: 0;
    overflow: hidden;
    -webkit-transition-timing-function: ease;
    -o-transition-timing-function: ease;
    transition-timing-function: ease;
    -webkit-transition-duration: .35s;
    -o-transition-duration: .35s;
    transition-duration: .35s;
    -webkit-transition-property: height, visibility;
    -o-transition-property: height, visibility;
    transition-property: height, visibility
}

.caret {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 2px;
    vertical-align: middle;
    border-top: 4px dashed;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent
}

.dropdown {
    position: relative
}

.dropdown-toggle:focus {
    outline: 0
}

.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 160px;
    padding: 5px 0;
    margin: 2px 0 0;
    font-size: 14px;
    text-align: left;
    list-style: none;
    background-color: #fff;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    border: 1px solid #ccc;
    border: 1px solid rgba(0, 0, 0, .15);
    border-radius: 4px;
    -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
    box-shadow: 0 6px 12px rgba(0, 0, 0, .175)
}

.dropdown-menu>li>a {
    display: block;
    padding: 3px 20px;
    clear: both;
    font-weight: 400;
    line-height: 1.42857143;
    color: #333;
    white-space: nowrap
}

.dropdown-menu>li>a:focus,
.dropdown-menu>li>a:hover {
    color: #262626;
    text-decoration: none;
    background-color: #f5f5f5
}

.open>.dropdown-menu {
    display: block
}

.open>a {
    outline: 0
}

.dropdown-menu-right {
    right: 0;
    left: auto
}

.dropdown-menu-left {
    right: auto;
    left: 0
}

.dropdown-header {
    display: block;
    padding: 3px 20px;
    font-size: 12px;
    line-height: 1.42857143;
    color: #777;
    white-space: nowrap
}

.navbar-fixed-bottom .dropdown .caret {
    content: "";
    border-top: 0;
    border-bottom: 4px dashed
}

.navbar-fixed-bottom .dropdown .dropdown-menu {
    top: auto;
    bottom: 100%;
    margin-bottom: 2px
}

@media (min-width:768px) {
    .navbar-right .dropdown-menu {
        right: 0;
        left: auto
    }
    .navbar-right .dropdown-menu-left {
        right: auto;
        left: 0
    }
}

.btn-group {
    position: relative;
    display: inline-block;
    vertical-align: middle
}

.btn-group>.btn {
    position: relative;
    float: left
}

.btn-group>.btn:active,
.btn-group>.btn:focus,
.btn-group>.btn:hover {
    z-index: 2
}

.btn-group .btn+.btn,
.btn-group .btn+.btn-group,
.btn-group .btn-group+.btn,
.btn-group .btn-group+.btn-group {
    margin-left: -1px
}

.btn-group>.btn:not(:first-child):not(:last-child):not(.dropdown-toggle) {
    border-radius: 0
}

.btn-group>.btn:first-child {
    margin-left: 0
}

.btn-group>.btn:first-child:not(:last-child):not(.dropdown-toggle) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
}

.btn-group>.btn:last-child:not(:first-child),
.btn-group>.dropdown-toggle:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
}

.btn-group>.btn-group {
    float: left
}

.btn-group>.btn-group:not(:first-child):not(:last-child)>.btn {
    border-radius: 0
}

.btn-group>.btn-group:first-child:not(:last-child)>.btn:last-child,
.btn-group>.btn-group:first-child:not(:last-child)>.dropdown-toggle {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
}

.btn-group>.btn-group:last-child:not(:first-child)>.btn:first-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
}

.btn-group .dropdown-toggle:active,
.btn-group.open .dropdown-toggle {
    outline: 0
}

.btn-group>.btn+.dropdown-toggle {
    padding-right: 8px;
    padding-left: 8px
}

.btn-group>.btn-lg+.dropdown-toggle {
    padding-right: 12px;
    padding-left: 12px
}

.btn-group.open .dropdown-toggle {
    -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125)
}

.btn-group.open .dropdown-toggle.btn-link {
    -webkit-box-shadow: none;
    box-shadow: none
}

.btn .caret {
    margin-left: 0
}

.btn-lg .caret {
    border-width: 5px 5px 0;
    border-bottom-width: 0
}

[data-toggle=buttons]>.btn input[type=checkbox],
[data-toggle=buttons]>.btn input[type=radio],
[data-toggle=buttons]>.btn-group>.btn input[type=checkbox],
[data-toggle=buttons]>.btn-group>.btn input[type=radio] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none
}

.input-group {
    position: relative;
    display: table;
    border-collapse: separate
}

.input-group[class*=col-] {
    float: none;
    padding-right: 0;
    padding-left: 0
}

.input-group .form-control {
    position: relative;
    z-index: 2;
    float: left;
    width: 100%;
    margin-bottom: 0
}

.input-group .form-control:focus {
    z-index: 3
}

.input-group-lg>.form-control,
.input-group-lg>.input-group-btn>.btn {
    height: 46px;
    padding: 10px 16px;
    font-size: 18px;
    line-height: 1.3333333;
    border-radius: 6px
}

select.input-group-lg>.form-control,
select.input-group-lg>.input-group-btn>.btn {
    height: 46px;
    line-height: 46px
}

select[multiple].input-group-lg>.form-control,
select[multiple].input-group-lg>.input-group-btn>.btn {
    height: auto
}

.input-group-sm>.form-control,
.input-group-sm>.input-group-btn>.btn {
    height: 30px;
    padding: 5px 10px;
    font-size: 12px;
    line-height: 1.5;
    border-radius: 3px
}

select.input-group-sm>.form-control,
select.input-group-sm>.input-group-btn>.btn {
    height: 30px;
    line-height: 30px
}

select[multiple].input-group-sm>.form-control,
select[multiple].input-group-sm>.input-group-btn>.btn {
    height: auto
}

.input-group .form-control,
.input-group-btn {
    display: table-cell
}

.input-group .form-control:not(:first-child):not(:last-child),
.input-group-btn:not(:first-child):not(:last-child) {
    border-radius: 0
}

.input-group-btn {
    width: 1%;
    white-space: nowrap;
    vertical-align: middle
}

.input-group .form-control:first-child,
.input-group-btn:first-child>.btn,
.input-group-btn:first-child>.btn-group>.btn,
.input-group-btn:first-child>.dropdown-toggle,
.input-group-btn:last-child>.btn-group:not(:last-child)>.btn,
.input-group-btn:last-child>.btn:not(:last-child):not(.dropdown-toggle) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
}

.input-group .form-control:last-child,
.input-group-btn:first-child>.btn-group:not(:first-child)>.btn,
.input-group-btn:first-child>.btn:not(:first-child),
.input-group-btn:last-child>.btn,
.input-group-btn:last-child>.btn-group>.btn,
.input-group-btn:last-child>.dropdown-toggle {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
}

.input-group-btn {
    position: relative;
    font-size: 0;
    white-space: nowrap
}

.input-group-btn>.btn {
    position: relative
}

.input-group-btn>.btn+.btn {
    margin-left: -1px
}

.input-group-btn>.btn:active,
.input-group-btn>.btn:focus,
.input-group-btn>.btn:hover {
    z-index: 2
}

.input-group-btn:first-child>.btn,
.input-group-btn:first-child>.btn-group {
    margin-right: -1px
}

.input-group-btn:last-child>.btn,
.input-group-btn:last-child>.btn-group {
    z-index: 2;
    margin-left: -1px
}

.nav {
    padding-left: 0;
    margin-bottom: 0;
    list-style: none
}

.nav>li {
    position: relative;
    display: block
}

.nav>li>a {
    position: relative;
    display: block;
    padding: 10px 15px
}

.nav>li>a:focus,
.nav>li>a:hover {
    text-decoration: none;
    background-color: #eee
}

.nav .open>a,
.nav .open>a:focus,
.nav .open>a:hover {
    background-color: #eee;
    border-color: #337ab7
}

.nav>li>a>img {
    max-width: none
}

.navbar {
    position: relative;
    min-height: 50px;
    margin-bottom: 20px;
    border: 1px solid transparent
}

@media (min-width:768px) {
    .navbar {
        border-radius: 4px
    }
}

@media (min-width:768px) {
    .navbar-header {
        float: left
    }
}

.navbar-collapse {
    padding-right: 15px;
    padding-left: 15px;
    overflow-x: visible;
    -webkit-overflow-scrolling: touch;
    border-top: 1px solid transparent;
    -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, .1);
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, .1)
}

.navbar-collapse.in {
    overflow-y: auto
}

@media (min-width:768px) {
    .navbar-collapse {
        width: auto;
        border-top: 0;
        -webkit-box-shadow: none;
        box-shadow: none
    }
    .navbar-collapse.collapse {
        display: block !important;
        height: auto !important;
        padding-bottom: 0;
        overflow: visible !important
    }
    .navbar-collapse.in {
        overflow-y: visible
    }
    .navbar-fixed-bottom .navbar-collapse,
    .navbar-fixed-top .navbar-collapse {
        padding-right: 0;
        padding-left: 0
    }
}

.navbar-fixed-bottom .navbar-collapse,
.navbar-fixed-top .navbar-collapse {
    max-height: 340px
}

@media (max-device-width:480px) and (orientation:landscape) {
    .navbar-fixed-bottom .navbar-collapse,
    .navbar-fixed-top .navbar-collapse {
        max-height: 200px
    }
}

.container-fluid>.navbar-collapse,
.container-fluid>.navbar-header,
.container>.navbar-collapse,
.container>.navbar-header {
    margin-right: -15px;
    margin-left: -15px
}

@media (min-width:768px) {
    .container-fluid>.navbar-collapse,
    .container-fluid>.navbar-header,
    .container>.navbar-collapse,
    .container>.navbar-header {
        margin-right: 0;
        margin-left: 0
    }
}

.navbar-fixed-bottom,
.navbar-fixed-top {
    position: fixed;
    right: 0;
    left: 0;
    z-index: 1030
}

@media (min-width:768px) {
    .navbar-fixed-bottom,
    .navbar-fixed-top {
        border-radius: 0
    }
}

.navbar-fixed-top {
    top: 0;
    border-width: 0 0 1px
}

.navbar-fixed-bottom {
    bottom: 0;
    margin-bottom: 0;
    border-width: 1px 0 0
}

.navbar-brand {
    float: left;
    height: 50px;
    padding: 15px 15px;
    font-size: 18px;
    line-height: 20px
}

.navbar-brand:focus,
.navbar-brand:hover {
    text-decoration: none
}

.navbar-brand>img {
    display: block
}

@media (min-width:768px) {
    .navbar>.container .navbar-brand,
    .navbar>.container-fluid .navbar-brand {
        margin-left: -15px
    }
}

.navbar-toggle {
    position: relative;
    float: right;
    padding: 9px 10px;
    margin-top: 8px;
    margin-right: 15px;
    margin-bottom: 8px;
    background-color: transparent;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 4px
}

.navbar-toggle:focus {
    outline: 0
}

.navbar-toggle .icon-bar {
    display: block;
    width: 22px;
    height: 2px;
    border-radius: 1px
}

.navbar-toggle .icon-bar+.icon-bar {
    margin-top: 4px
}

@media (min-width:768px) {
    .navbar-toggle {
        display: none
    }
}

.navbar-nav {
    margin: 7.5px -15px
}

.navbar-nav>li>a {
    padding-top: 10px;
    padding-bottom: 10px;
    line-height: 20px
}

@media (max-width:767px) {
    .navbar-nav .open .dropdown-menu {
        position: static;
        float: none;
        width: auto;
        margin-top: 0;
        background-color: transparent;
        border: 0;
        -webkit-box-shadow: none;
        box-shadow: none
    }
    .navbar-nav .open .dropdown-menu .dropdown-header,
    .navbar-nav .open .dropdown-menu>li>a {
        padding: 5px 15px 5px 25px
    }
    .navbar-nav .open .dropdown-menu>li>a {
        line-height: 20px
    }
    .navbar-nav .open .dropdown-menu>li>a:focus,
    .navbar-nav .open .dropdown-menu>li>a:hover {
        background-image: none
    }
}

@media (min-width:768px) {
    .navbar-nav {
        float: left;
        margin: 0
    }
    .navbar-nav>li {
        float: left
    }
    .navbar-nav>li>a {
        padding-top: 15px;
        padding-bottom: 15px
    }
}

.navbar-form {
    padding: 10px 15px;
    margin-top: 8px;
    margin-right: -15px;
    margin-bottom: 8px;
    margin-left: -15px;
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;
    -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, .1), 0 1px 0 rgba(255, 255, 255, .1);
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, .1), 0 1px 0 rgba(255, 255, 255, .1)
}

@media (min-width:768px) {
    .navbar-form .form-group {
        display: inline-block;
        margin-bottom: 0;
        vertical-align: middle
    }
    .navbar-form .form-control {
        display: inline-block;
        width: auto;
        vertical-align: middle
    }
    .navbar-form .input-group {
        display: inline-table;
        vertical-align: middle
    }
    .navbar-form .input-group .form-control,
    .navbar-form .input-group .input-group-btn {
        width: auto
    }
    .navbar-form .input-group>.form-control {
        width: 100%
    }
    .navbar-form .control-label {
        margin-bottom: 0;
        vertical-align: middle
    }
}

@media (max-width:767px) {
    .navbar-form .form-group {
        margin-bottom: 5px
    }
    .navbar-form .form-group:last-child {
        margin-bottom: 0
    }
}

@media (min-width:768px) {
    .navbar-form {
        width: auto;
        padding-top: 0;
        padding-bottom: 0;
        margin-right: 0;
        margin-left: 0;
        border: 0;
        -webkit-box-shadow: none;
        box-shadow: none
    }
}

.navbar-nav>li>.dropdown-menu {
    margin-top: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0
}

.navbar-fixed-bottom .navbar-nav>li>.dropdown-menu {
    margin-bottom: 0;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0
}

.navbar-btn {
    margin-top: 8px;
    margin-bottom: 8px
}

.navbar-btn.btn-sm {
    margin-top: 10px;
    margin-bottom: 10px
}

.navbar-btn.btn-xs {
    margin-top: 14px;
    margin-bottom: 14px
}

.navbar-text {
    margin-top: 15px;
    margin-bottom: 15px
}

@media (min-width:768px) {
    .navbar-text {
        float: left;
        margin-right: 15px;
        margin-left: 15px
    }
}

@media (min-width:768px) {
    .navbar-left {
        float: left !important
    }
    .navbar-right {
        float: right !important;
        margin-right: -15px
    }
    .navbar-right~.navbar-right {
        margin-right: 0
    }
}

.navbar-default {
    background-color: #f8f8f8;
    border-color: #e7e7e7
}

.navbar-default .navbar-brand {
    color: #777
}

.navbar-default .navbar-brand:focus,
.navbar-default .navbar-brand:hover {
    color: #5e5e5e;
    background-color: transparent
}

.navbar-default .navbar-text {
    color: #777
}

.navbar-default .navbar-nav>li>a {
    color: #777
}

.navbar-default .navbar-nav>li>a:focus,
.navbar-default .navbar-nav>li>a:hover {
    color: #333;
    background-color: transparent
}

.navbar-default .navbar-toggle {
    border-color: #ddd
}

.navbar-default .navbar-toggle:focus,
.navbar-default .navbar-toggle:hover {
    background-color: #ddd
}

.navbar-default .navbar-toggle .icon-bar {
    background-color: #888
}

.navbar-default .navbar-collapse,
.navbar-default .navbar-form {
    border-color: #e7e7e7
}

.navbar-default .navbar-nav>.open>a,
.navbar-default .navbar-nav>.open>a:focus,
.navbar-default .navbar-nav>.open>a:hover {
    color: #555;
    background-color: #e7e7e7
}

@media (max-width:767px) {
    .navbar-default .navbar-nav .open .dropdown-menu>li>a {
        color: #777
    }
    .navbar-default .navbar-nav .open .dropdown-menu>li>a:focus,
    .navbar-default .navbar-nav .open .dropdown-menu>li>a:hover {
        color: #333;
        background-color: transparent
    }
}

.navbar-default .navbar-link {
    color: #777
}

.navbar-default .navbar-link:hover {
    color: #333
}

.navbar-default .btn-link {
    color: #777
}

.navbar-default .btn-link:focus,
.navbar-default .btn-link:hover {
    color: #333
}

.navbar-default .btn-link[disabled]:focus,
.navbar-default .btn-link[disabled]:hover {
    color: #ccc
}

.breadcrumb {
    padding: 8px 15px;
    margin-bottom: 20px;
    list-style: none;
    background-color: #f5f5f5;
    border-radius: 4px
}

.breadcrumb>li {
    display: inline-block
}

.breadcrumb>li+li:before {
    padding: 0 5px;
    color: #ccc;
    content: "/\00a0"
}

.label {
    display: inline;
    padding: .2em .6em .3em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: .25em
}

a.label:focus,
a.label:hover {
    color: #fff;
    text-decoration: none;
    cursor: pointer
}

.label:empty {
    display: none
}

.btn .label {
    position: relative;
    top: -1px
}

.label-default {
    background-color: #777
}

.label-default[href]:focus,
.label-default[href]:hover {
    background-color: #5e5e5e
}

.label-success {
    background-color: #5cb85c
}

.label-success[href]:focus,
.label-success[href]:hover {
    background-color: #449d44
}

.label-info {
    background-color: #5bc0de
}

.label-info[href]:focus,
.label-info[href]:hover {
    background-color: #31b0d5
}

@-webkit-keyframes progress-bar-stripes {
    from {
        background-position: 40px 0
    }
    to {
        background-position: 0 0
    }
}

@-o-keyframes progress-bar-stripes {
    from {
        background-position: 40px 0
    }
    to {
        background-position: 0 0
    }
}

@keyframes progress-bar-stripes {
    from {
        background-position: 40px 0
    }
    to {
        background-position: 0 0
    }
}

.media {
    margin-top: 15px
}

.media:first-child {
    margin-top: 0
}

.media,
.media-body {
    overflow: hidden;
    zoom: 1
}

.media-body {
    width: 10000px
}

.media-right {
    padding-left: 10px
}

.media-left {
    padding-right: 10px
}

.media-body,
.media-left,
.media-right {
    display: table-cell;
    vertical-align: top
}

.media-bottom {
    vertical-align: bottom
}

.panel {
    margin-bottom: 20px;
    background-color: #fff;
    border: 1px solid transparent;
    border-radius: 4px;
    -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, .05);
    box-shadow: 0 1px 1px rgba(0, 0, 0, .05)
}

.panel-body {
    padding: 15px
}

.panel-title {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 16px;
    color: inherit
}

.panel-title>a {
    color: inherit
}

.panel-footer {
    padding: 10px 15px;
    background-color: #f5f5f5;
    border-top: 1px solid #ddd;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px
}

.panel-group {
    margin-bottom: 20px
}

.panel-group .panel {
    margin-bottom: 0;
    border-radius: 4px
}

.panel-group .panel+.panel {
    margin-top: 5px
}

.panel-group .panel-footer {
    border-top: 0
}

.panel-group .panel-footer+.panel-collapse .panel-body {
    border-bottom: 1px solid #ddd
}

.panel-default {
    border-color: #ddd
}

.panel-default>.panel-footer+.panel-collapse>.panel-body {
    border-bottom-color: #ddd
}

.panel-success {
    border-color: #d6e9c6
}

.panel-success>.panel-footer+.panel-collapse>.panel-body {
    border-bottom-color: #d6e9c6
}

.panel-info {
    border-color: #bce8f1
}

.panel-info>.panel-footer+.panel-collapse>.panel-body {
    border-bottom-color: #bce8f1
}

.clearfix:after,
.clearfix:before,
.container-fluid:after,
.container-fluid:before,
.container:after,
.container:before,
.nav:after,
.nav:before,
.navbar-collapse:after,
.navbar-collapse:before,
.navbar-header:after,
.navbar-header:before,
.navbar:after,
.navbar:before,
.panel-body:after,
.panel-body:before,
.row:after,
.row:before {
    display: table;
    content: " "
}

.clearfix:after,
.container-fluid:after,
.container:after,
.nav:after,
.navbar-collapse:after,
.navbar-header:after,
.navbar:after,
.panel-body:after,
.row:after {
    clear: both
}

.center-block {
    display: block;
    margin-right: auto;
    margin-left: auto
}

.hide {
    display: none !important
}

.text-hide {
    font: 0/0 a;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0
}

.hidden {
    display: none !important
}

@-ms-viewport {
    width: device-width
}

@media (max-width:767px) {
    .hidden-xs {
        display: none !important
    }
}

@media (min-width:768px) and (max-width:991px) {
    .hidden-sm {
        display: none !important
    }
}

@media (min-width:992px) and (max-width:1199px) {
    .hidden-md {
        display: none !important
    }
}

@media (min-width:1200px) {
    .hidden-lg {
        display: none !important
    }
}

.container {
    margin-right: auto;
    margin-left: auto;
    padding-left: 15px;
    padding-right: 15px
}

@media only screen and (min-width:768px) {
    .container {
        width: 750px
    }
}

@media only screen and (min-width:992px) {
    .container {
        width: 990px
    }
}

@media only screen and (min-width:1280px) {
    .container {
        width: 1260px
    }
}

@media only screen and (min-width:1400px) {
    .container {
        width: 1340px
    }
}

body {
    font-size: 14px;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    color: #292929;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
    padding: 2px 0 0 0;
    line-height: 1.3;
    font-weight: 400
}

a {
    color: #3b4241;
    text-decoration: none;
    outline: 0
}

a:active,
a:focus,
a:hover {
    text-decoration: none;
    color: #ff9800
}

.a-center {
    text-align: center
}

.no-results {
    display: block;
    font-size: 20px;
    font-style: italic
}

#header .header-wrapper .top {
    color: #fff;
    text-align: center;
    background-color: #292929;
    padding: 5px 0
}

@media only screen and (min-width:768px) {
    #header .header-wrapper .top {
        min-height: 30px
    }
}

#header .header-wrapper .main-menu .logo {
    padding: 10px 0 10px 0;
    text-align: center
}

@media only screen and (min-width:768px) {
    #header .header-wrapper .main-menu .logo {
        padding: 10px 0 10px 0;
        text-align: left
    }
}

#header .header-wrapper .main-menu .logo a {
    display: inline-block;
    min-height: 40px
}

@media only screen and (min-width:768px) {
    #header .header-wrapper .main-menu .logo a {
        min-height: 66px
    }
}

#header .header-wrapper .main-menu .logo img {
    width: auto
}

#header .header-wrapper .main-menu .logo h3 a {
    border-top: 3px solid #3b4241;
    border-bottom: 3px solid #3b4241;
    min-height: auto;
    padding: 6px 0;
    margin: 0;
    font-style: italic
}

#header .header-wrapper .main-menu .main-navigation {
    text-align: right;
    padding: 15px 0 15px 0
}

@media only screen and (min-width:768px) {
    #header .header-wrapper .main-menu .main-navigation {
        padding: 23px 0 0 0
    }
}

#header .header-wrapper .main-menu .main-navigation .btn-group {
    position: relative;
    display: inline-block;
    vertical-align: middle
}

#header .header-wrapper .main-menu .main-navigation .btn-group.open .dropdown-toggle {
    color: #3b4241;
    -webkit-box-shadow: inset 0 0 0 transparent;
    box-shadow: inset 0 0 0 transparent
}

#header .header-wrapper .main-menu .main-navigation .btn-group .dropdown-menu {
    min-width: 175px;
    padding: 0 0;
    margin: 0;
    top: 63px;
    list-style: none;
    font-size: 14px;
    text-align: left;
    background-color: #fff;
    border: 1px solid #e1e1e1;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    -webkit-box-shadow: inset 0 0 0 transparent;
    box-shadow: inset 0 0 0 transparent;
    background-clip: padding-box
}

#header .header-wrapper .main-menu .main-navigation .btn-group .dropdown-menu a {
    display: block;
    padding: 5px 20px;
    color: #000;
    font-size: 18px;
    text-align: left
}

#header .header-wrapper .main-menu .main-navigation .btn-group .dropdown-menu a:hover {
    background-color: #3b4241;
    color: #fff
}

#header .header-wrapper .main-menu .main-navigation .btn-group .dropdown-menu a .fa {
    font-size: 14px;
    margin-right: 10px
}

#header .header-wrapper .main-menu .btn-as .fa {
    font-size: 18px;
    margin-left: 0
}

#header .header-wrapper .main-menu .btn-as.mobile-menu {
    color: #000;
    min-width: 30px;
    padding: 0 0;
    height: 30px !important;
    min-height: 30px !important;
    margin-top: 2px
}

#header .header-wrapper .main-menu .btn-as.mobile-menu .fa {
    font-size: 24px;
    margin-right: 0 !important;
    margin-left: 0 !important
}

#header .header-wrapper .main-menu .mobile-menu-wrapper ul {
    list-style: none;
    margin: 10px 0 0 0;
    padding: 0
}

#header .header-wrapper .main-menu .mobile-menu-wrapper ul li {
    display: block;
    border-top: 1px solid #e1e1e1
}

#header .header-wrapper .main-menu .mobile-menu-wrapper ul li a {
    display: block;
    padding: 5px 0 5px 5px;
    color: #000;
    font-size: 18px;
    text-align: left
}

#header .header-wrapper .main-menu .mobile-menu-wrapper ul li a .fa {
    font-size: 14px;
    margin-left: 0;
    margin-right: 10px
}

#footer {
    min-height: 100px;
    background-color: rgba(0, 0, 0, .75);
    padding: 0 0 75px 0
}

@media only screen and (min-width:768px) {
    #footer {
        padding: 0 0 50px 0;
        margin-top: 80px
    }
}

#footer h3 {
    color: #fff;
    font-size: 18px;
    text-transform: uppercase;
    padding: 0 0 5px 0;
    margin: 0 0 15px 0;
    border-bottom: 1px solid #fff
}

form {
    position: relative;
    margin: 0;
    padding: 0
}

label {
    font-weight: 400
}

.input-group.inline {
    display: inline-block;
    width: auto !important;
    padding: 0 20px 10px 0
}

.input-group.inline input[type=checkbox]+label {
    margin: 0
}

.input-group .form-control,
.input-group input[type=email],
.input-group input[type=password],
.input-group input[type=search],
.input-group input[type=tel],
.input-group input[type=text] {
    border-right-width: 0
}

.input-group .form-control {
    font-size: 14px;
    line-height: 14px;
    background-color: #fff;
    padding: 0 10px 0 10px;
    height: 40px;
    border-color: #b1b1b1;
    -webkit-box-shadow: inset 0 0 0 transparent;
    box-shadow: inset 0 0 0 transparent;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0
}

.input-group .form-control:active,
.input-group .form-control:focus {
    outline: 0;
    -webkit-box-shadow: inset 0 0 0 transparent;
    box-shadow: inset 0 0 0 transparent;
    border-color: #292929
}

::-webkit-input-placeholder {
    color: #e1e1e1
}

:-moz-placeholder {
    color: #e1e1e1
}

::-moz-placeholder {
    color: #e1e1e1
}

:-ms-input-placeholder {
    color: #e1e1e1;
    line-height: 40px
}

@media only screen and (min-width:768px) {
    :-ms-input-placeholder {
        line-height: 14px
    }
}

.form-control,
input[type=email],
input[type=password],
input[type=search],
input[type=tel],
input[type=text] {
    width: 100%;
    border: 1px solid #b1b1b1;
    color: #292929;
    font-size: 16px;
    line-height: 40px;
    margin: 0;
    padding: 0 10px 0 10px;
    height: 40px;
    -webkit-box-shadow: inset 0 0 0 transparent;
    box-shadow: inset 0 0 0 transparent;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    -webkit-appearance: none
}

@media only screen and (min-width:768px) {
    .form-control,
    input[type=email],
    input[type=password],
    input[type=search],
    input[type=tel],
    input[type=text] {
        font-size: 14px;
        line-height: 14px
    }
}

.form-control:active,
.form-control:focus,
input[type=email]:active,
input[type=email]:focus,
input[type=password]:active,
input[type=password]:focus,
input[type=search]:active,
input[type=search]:focus,
input[type=tel]:active,
input[type=tel]:focus,
input[type=text]:active,
input[type=text]:focus {
    outline: 0;
    -webkit-box-shadow: inset 0 0 0 transparent;
    box-shadow: inset 0 0 0 transparent;
    border-color: #292929
}

.form-control.form-control,
input[type=email].form-control,
input[type=password].form-control,
input[type=search].form-control,
input[type=tel].form-control,
input[type=text].form-control {
    font-size: 16px;
    line-height: 16px;
    background-color: #fff
}

@media only screen and (min-width:768px) {
    .form-control.form-control,
    input[type=email].form-control,
    input[type=password].form-control,
    input[type=search].form-control,
    input[type=tel].form-control,
    input[type=text].form-control {
        font-size: 14px;
        line-height: 14px
    }
}

.form-control.form-control:active,
.form-control.form-control:focus,
input[type=email].form-control:active,
input[type=email].form-control:focus,
input[type=password].form-control:active,
input[type=password].form-control:focus,
input[type=search].form-control:active,
input[type=search].form-control:focus,
input[type=tel].form-control:active,
input[type=tel].form-control:focus,
input[type=text].form-control:active,
input[type=text].form-control:focus {
    outline: 0;
    -webkit-box-shadow: inset 0 0 0 transparent;
    box-shadow: inset 0 0 0 transparent
}

a.form-control {
    width: 100%;
    border: 1px solid #b1b1b1;
    color: #292929;
    font-size: 14px;
    line-height: 40px;
    margin: 0;
    padding: 12px 10px 0 10px;
    height: 40px;
    -webkit-box-shadow: inset 0 0 0 transparent;
    box-shadow: inset 0 0 0 transparent;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    -webkit-appearance: none
}

a.form-control:active,
a.form-control:focus {
    outline: 0;
    -webkit-box-shadow: inset 0 0 0 transparent;
    box-shadow: inset 0 0 0 transparent;
    border-color: #292929
}

input[type=checkbox] {
    position: absolute;
    left: -1000em
}

input[type=checkbox]+label {
    position: relative;
    padding: 2px 0 8px 25px;
    font-size: 14px;
    margin: 0 0 10px 0
}

@media only screen and (min-width:768px) {
    input[type=checkbox]+label {
        margin: 10px 0 0 0
    }
}

input[type=checkbox]+label:before {
    content: "\f096";
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    display: block;
    font-family: FontAwesome;
    color: #b1b1b1;
    font-size: 24px;
    line-height: 24px;
    margin: 0;
    cursor: pointer
}

input[type=checkbox]:checked+label:before {
    content: "\f046";
    color: #3b4241
}

input[type=radio] {
    position: absolute;
    left: -1000em
}

input[type=radio]+label {
    position: relative;
    padding: 2px 0 8px 35px;
    font-size: 14px;
    margin: 0
}

@media only screen and (min-width:768px) {
    input[type=radio]+label {
        margin: 10px 0 0 0
    }
}

input[type=radio]+label:before {
    content: "\f1db";
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    display: block;
    font-family: FontAwesome;
    color: #b1b1b1;
    font-size: 24px;
    line-height: 24px;
    margin: 0;
    cursor: pointer
}

input[type=radio]:checked+label:before {
    content: "\f28e";
    color: #3b4241
}

button,
input[type=submit] {
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    padding: 0 15px;
    font-size: 20px;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: #fff;
    min-height: 40px;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0
}

select.select2 {
    height: 28px
}

.select2-container {
    box-sizing: border-box;
    display: block !important;
    margin: 0;
    position: relative;
    vertical-align: middle
}

.select2-container .select2-search--inline {
    float: left
}

.select2-dropdown {
    background-color: #fff;
    border: 1px solid #b1b1b1;
    border-radius: 0;
    box-sizing: border-box;
    display: block;
    position: absolute;
    left: -100000px;
    width: 100%;
    z-index: 1051
}

.select2-results {
    display: block
}

.select2-results__option {
    padding: 6px;
    user-select: none;
    -webkit-user-select: none
}

.select2-results__option[aria-selected] {
    cursor: pointer
}

.select2-container--open .select2-dropdown {
    left: 0
}

.select2-search--dropdown {
    display: block;
    padding: 4px
}

.select2-search--dropdown.select2-search--hide {
    display: none
}

.breadcrumb {
    padding: 0;
    background-color: #fff;
    list-style: none;
    overflow: hidden;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    padding-left: 15px
}

.breadcrumb li a {
    color: #fff;
    text-decoration: none;
    text-transform: uppercase;
    min-height: 25px;
    line-height: 25px;
    font-size: 12px
}

.btn-as {
    display: inline-block;
    position: relative;
    overflow: hidden;
    border: 1px solid #3b4241;
    background-color: #3b4241;
    color: #fff;
    text-transform: uppercase;
    font-size: 16px;
    text-decoration: none;
    line-height: 40px;
    z-index: 0;
    padding: 0 20px;
    height: 40px;
    min-width: 90px;
    transition: all .2s ease;
    outline: 0 !important;
    vertical-align: top
}

.btn-as:active,
.btn-as:focus {
    outline: 0;
    color: #fff
}

.btn-as:hover {
    color: #fff;
    background-color: #ff9800;
    border: 1px solid #ff9800
}

.btn-as.black {
    background-color: #b1b1b1;
    border-color: #b1b1b1
}

.btn-as.black:hover {
    background-color: #000;
    border-color: #000
}

.btn-as.a-center {
    margin: 0 auto !important
}

.btn-as .fa {
    font-size: 14px;
    margin-right: 5px
}

.btn-as+.btn-as {
    margin-left: 10px
}

.overlay-loading {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 600;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, .8);
    display: none
}

.overlay-loading .icon-placeholder {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 64px;
    height: 64px;
    margin-top: -32px;
    margin-left: -32px
}

.overlay-loading .fa {
    font-size: 64px;
    color: #3b4241
}

ul li a {
    font-size: 20px;
    line-height: 40px
}

.collapseSlim h4.collapse-toggle {
    background: #f5f5f5;
    padding: 15px 20px;
    cursor: pointer;
    color: #333
}

.collapseSlim {
    box-shadow: 0 10px 16px -13px rgba(0, 0, 0, .5)
}

.listaHorarios {
    text-align: center;
    margin: 0 auto;
    display: block;
    padding: 8px 3px;
    box-shadow: 0 1px 8px -4px rgba(0, 0, 0, .5)
}

.listaHorarios li {
    list-style-type: none;
    padding: 8px 6px;
    min-width: 60px;
    background: #f5f5f5;
    display: inline-block;
    margin: 2px 4px
}

.title_p {
    color: #3c763d !important;
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 0
}

ul.breadcrumb.col-lg-12 {
    margin-bottom: 0;
    background: #005f56;
    margin-top: 15px
}

.h1,
h1 {
    font-size: 25px;
    box-shadow: 0 1px 8px -4px rgba(0, 0, 0, .5);
    padding: 8px 15px;
    margin-bottom: 4px;
    display: inline-block;
    width: 100%;
    box-shadow: 0 10px 16px -13px rgba(0, 0, 0, .5);
    background: #3b4241;
    color: #fff
}

img {
    max-width: 100%
}

nav.navbar.navbar-default {
    background: #fff;
    border: 0;
    box-shadow: 0 10px 16px -13px rgba(0, 0, 0, .5);
    border-top: 5px solid #005f56;
    border-radius: 0
}

.nav>li>a {
    padding: 15px 9px;
    font-size: 17px
}

@media only screen and (max-width:767px) {
    .nav>li>a.dropdown-toggle {
        padding: 20px 9px
    }
    .navbar-nav .open .dropdown-menu>li>a {
        line-height: 35px
    }
}

.panel {
    -webkit-box-shadow: 0 10px 16px -13px rgba(0, 0, 0, .5);
    box-shadow: 0 10px 16px -13px rgba(0, 0, 0, .5);
    border-radius: 0
}

.panel {
    border: 0
}

.mainContent {
    -webkit-box-shadow: 0 10px 16px -13px rgba(0, 0, 0, .5);
    box-shadow: 0 10px 16px -13px rgba(0, 0, 0, .5);
    padding: 5px 15px
}

@media only screen and (max-width:767px) {
    .mainContent {
        padding: 5px 0
    }
}

.mainContent h2 {
    background: #005f56;
    padding-left: 14px;
    padding-bottom: 2px;
    color: #fff
}

.mainContent h2 a {
    color: #fff
}

.sidebarContent {
    padding-top: 10px;
    float: left
}

.panel-collapse .panel-body>.horLink {
    display: block;
    text-align: center
}

.mainContent .btn-default {
    white-space: normal
}

@media only screen and (min-width:728px) {
    .rappi {
        padding: 6px 15px 15px 15px !important
    }
    .googleAdContainer ins {
        margin: 0 auto !important;
        text-align: center
    }
    .googleAdContainer {
        width: 100% !important;
        padding: 0 !important;
        background: #fff;
        box-shadow: 0 10px 16px -13px rgba(0, 0, 0, .5)
    }
    .googleAdContainer.adTop {
        margin-bottom: 20px !important
    }
}

.collapseSlim .caret {
    border-width: 8px
}

/*INLINE*/
.mainContent {
    background: #fff;
    padding: 20px 15px;
}

.sidebar .googleAdContainer {
    display: block;
}

.sidebar .responsiveInSidebar {
    max-height: 300px;
    height: 250px
}

@media(max-width: 480px) {
    .sidebar .googleAdContainer {
        display: none;
    }
}

@media(max-width: 880px) {
    .mainContent {
        padding: 20px 15px 20px 15px;
    }
    h1 {
        font-size: 20px;
    }
    .mainContent h2 {
        padding: 5px 0;
        font-size: 20px;
    }
}

@media only screen and (max-width: 767px) {
    .nav>li>a.dropdown-toggle {
        text-align: center;
        background: #f0f0f0;
        margin: 3px 0;
    }
    .adTop ins {
        min-height: 250px;
    }
    .infol {
        width: 320px;
        max-width: 100%
    }
}

.ins_top,
.ins_bott {
    height: 250px;
}

@media only screen and (min-width: 728px) {
    .apm {
        display: none !important;
    }
    .apd {
        display: block !important;
    }
    .rappi {
        max-width: 740px !important;
        border: 1px solid #dedede;
    }
    .ins_top,
    .ins_bott {
        height: 90px;
    }
}

.sidebarContent {
    width: 100%;
    background: #fff;
    box-shadow: 0 10px 16px -13px rgba(0, 0, 0, .5);
}

.panel-body>a.horLink {
    box-shadow: none;
    background: none;
}

body {
    background-color: #f0f0f0 !important;
}

.listaHorarios {
    box-shadow: none;
    border: 1px solid #f0f0f0;
}

.mainContent h2,
.sidebarContent h3 {
    text-align: center;
}

.horLink {
    box-shadow: 0 10px 16px -13px rgba(0, 0, 0, .5);
    background: #f5f5f5;
    padding: 12px 20px;
    cursor: pointer;
    color: #333;
    display: block;
    width: 100%;
    margin-bottom: 20px;
    font-size: 20px;
}

.navbar-default .navbar-brand {
    margin-top: -9px;
}

.footer-links{
    list-style: none;display: inline-block;padding: 15px;
}